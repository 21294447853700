import { Box, Grid, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const endpoint = "http://localhost:8000/posts";

const BFT = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const getUrl = await axios.get(endpoint).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Box>
        <Box sx={{ backgroundColor: "#000" }}>
          <Box sx={{ marginLeft: "10%", paddingY: "5%" }}>
            <h2 style={{ color: "#fff" }}>First </h2>
          </Box>
        </Box>
        <Grid container lg={12}>
          {data
            .filter(({ tags }) => tags === "1")
            .map(({ title }) => (
              <Grid lg={3}>
                <Box sx={{ marginX: "6%", marginY: "10%" }}>
                  <Paper elevation={6}>
                    <Box sx={{ padding: "10%" }}>{title}</Box>
                  </Paper>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default BFT;
