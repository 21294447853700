import {
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../assets/Heard Brand_Right Icon Combo Mark_White-01.webp";
import { NavLink } from "react-router-dom";

const CustomNav = styled("div")({
  textDecoration: "none",
  color: "GrayText",
  fontFamily:
    "wfont_e9f997_c6ade2174a214d059c3d2c1784c22f5a,wf_c6ade2174a214d059c3d2c178,orig_jubilat_regular",
  "&:hover": {
    cursor: "pointer",
    color: "#fff",
  },
});
const MenuBar = () => {
  const pages = [
    "event-production",
    "design-decor",
    "sports-and-entertainment-venues",
    "destination-management",
    "events-experiential",
    "brand-activations-tours",
  ];

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      {show === true ? (
        <Box
          sx={{
            paddingX: "15%",
            display: "flex",
            width: "100%",
            minWidth: "980px",
            position: "fixed",
            height: "4rem",
            top: 0,
            transition: "0.3s linear",
            justifyContent: "stretch",
            alignItems: "center",
            borderBottom: "1px solid rgba(0, 0, 0, .1)",
            zIndex: 40,
            bgBlur: "#000",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Box sx={{}}>
            <img alt="" src={logo} />
          </Box>

          <Box
            sx={{
              flexGrow: 0.5,
              display: "flex",
              marginLeft: "15%",
            }}
          >
            <Grid container lg={12}>
              <Grid item lg={4}>
                <Box>
                  <NavLink style={{ textDecoration: "none" }} to="/">
                    <CustomNav>Home</CustomNav>
                  </NavLink>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  onClick={handleOpenUserMenu}
                  id="menu-appbar"
                >
                  <CustomNav>Capabilities</CustomNav>
                </NavLink>
              </Grid>
              <Grid item lg={4}>
                <NavLink style={{ textDecoration: "none" }} to="/contact-us">
                  <CustomNav>Engage Us</CustomNav>
                </NavLink>
              </Grid>
            </Grid>
            <Menu
              sx={{
                mt: "45px",
                ml: "auto",
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {pages.map((page) => (
                <NavLink to={`/${page}`}>
                  <MenuItem
                    sx={{
                      transition: "0  .3s linear",
                      justifyContent: "stretch",
                      alignItems: "center",
                      borderBottom: "1px solid rgba(0, 0, 0, .1)",
                      zIndex: 40,
                      bgBlur: "#000",
                      width: "auto",
                    }}
                    key={page}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            height: "4rem",
            width: "100%",
            zIndex: 40,
            borderBottom: "1px solid rgba(0, 0, 0, .1)",
            position: "fixed",
            top: "-80px",
            transition: "0.3s linear",
            marginLeft: "-500px",
            paddingX: "15%",
            display: "flex",
            minWidth: "980px",
          }}
        >
          {" "}
          <Box>
            <img alt="" src={logo} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MenuBar;
