import { Box, Grid } from "@mui/material";
import React from "react";
import logo from "../../../assets/Heard Brand_Centered Combo Mark_Black_CM.webp";
import { Heading, SubTitle, Title, Span } from "../../mini";
import CustomBtn from "../../mini/CustomBtn";
const Mission = () => {
  return (
    <>
      <Box>
        <Box
          sx={{
            marginTop: "-1%",
            marginBottom: "-50%",

            display: { xs: "none", lg: "flex", md: "flex" },
          }}
        >
          <video
            role="presentation"
            crossOrigin="anonymous"
            playsInline
            preload="auto"
            muted
            loop
            autoPlay
            src="https://video.wixstatic.com/video/e9f997_a4c8851e136a4fb5824c6bc4f53477ba/1080p/mp4/file.mp4"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        <Grid container lg={12} sx={{ height: "100%", marginLeft: "-20%" }}>
          <Grid
            item
            lg={12}
            md={12}
            sx={{
              textAlign: "center",
              marginTop: { md: "-50px", lg: "0%" },
            }}
          >
            <img alt="" src={logo} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sx={{
              marginTop: { md: "-20px", lg: "50px" },
              paddingLeft: { lg: "33%", md: "23%", xs: "18%" },
              paddingRight: { lg: "33%", md: "23%", xs: "18%" },
            }}
          >
            <Heading title="Event Services and Audio-Visual Production Company" />
          </Grid>

          <Grid
            sx={{
              paddingTop: "25px",
              paddingLeft: { lg: "33%", md: "23%", xs: "18%" },
              paddingRight: { lg: "33%", md: "23%", xs: "18%" },
            }}
            item
            lg={12}
            md={12}
          >
            <SubTitle SubTitle="Our Mission" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sx={{
              paddingLeft: { lg: "20%", md: "23%", xs: "18%" },
              paddingRight: { lg: "20%", md: "23%", xs: "18%" },
              paddingY: "20px",
            }}
          >
            <Title title="Opening doorways to transformative experiences." />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sx={{
              paddingLeft: { lg: "31%", md: "22%", xs: "18%" },
              paddingRight: { lg: "26%", md: "25%", xs: "18%" },
              paddingY: "20px",
            }}
          >
            <Span
              span="We partner to inform, inspire, and impact your audience by
                combining technology, content, and an unlimited passion for
                flawless execution. Heard can support your live, virtual &
                hybrid event top to bottom with services including,
                pre-production, AV Technology, Event Management."
            />
            <Span span="Design and Décor, and more." />
            <Span span="Our group of professionals will collaborate with your brand" />
            <Box sx={{ paddingY: "20px" }}>
              <CustomBtn title="Begin Planning" to="/contact-us" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Mission;
