import { Box } from "@mui/material";
import React from "react";
import Hero from "../components/sections/Hero/Hero";

import {
  Breaker,
  ClientIndust,
  Leadership,
  Mission,
} from "../components/sections";
import SEO from "../hooks/SEO";
import Footer from "../layouts/footer/Footer";

const Landing = () => {
  return (
    <>
      <SEO
        title="HEARD GLOBAL | Event Services & Production"
        description="Beginner friendly page for learning React Helmet."
        name="Heard Global."
        type="landing_page"
      />
      <Box sx={{ height: "auto", marginX: "-3%" }}>
        <Hero />
        <Mission />
        <Box sx={{ marginTop: "20%" }}>
          <Breaker img="https://static.wixstatic.com/media/e9f997_c7fdaac036af4e82a938d13bcb9b5c35~mv2.jpg/v1/fill/w_980,h_603,al_b,q_85,usm_0.66_1.00_0.01,enc_auto/e9f997_c7fdaac036af4e82a938d13bcb9b5c35~mv2.jpg" />
        </Box>

        <ClientIndust />
        <Leadership />
      </Box>
    </>
  );
};

export default Landing;
