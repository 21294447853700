import { Box, Typography } from "@mui/material";
import React from "react";

const Title = (props) => {
  const { title } = props;
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: "20px", md: "20px", sm: "16px", xs: "18px" },
            lineHeight: "normal",
            textAlign: "center",
            fontFamily: "inherit",
            color: "#000",
            fontWeight: 600,
            letterSpacing: "0.15em",
            marginBottom: "-20px",
          }}
          variant="h2"
        >
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default Title;
