import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import logo from "../../assets/Heard Brand_Right Icon Combo Mark_White-01.webp";
import { NavLink } from "react-router-dom/dist";
const Footer = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#000", width: "100%" }}>
        <Grid container lg={12} sx={{ marginX: "10%", paddingY: "2%" }}>
          <Grid item lg={2}>
            <img alt="Heard-Global" src={logo} />
          </Grid>

          <Grid item lg={2}>
            <p style={{ color: "#ddd" }}>
              Engage{" "}
              <span style={{ fontWeight: "bold", color: "#fff" }}>people</span>
            </p>
            <p style={{ color: "#ddd" }}>
              Create{" "}
              <span style={{ fontWeight: "bold", color: "#fff" }}>moments</span>
            </p>
            <p style={{ color: "#ddd" }}>
              Build{" "}
              <span style={{ fontWeight: "bold", color: "#fff" }}>
                connections
              </span>
            </p>
          </Grid>
          <Box>
            <Divider
              orientation="vertical"
              sx={{ height: "80%", backgroundColor: "#fff" }}
            />
          </Box>
          <Grid item lg={4} sx={{ textAlign: "center" }}>
            <p style={{ color: "#ddd", lineHeight: "1.5em" }}>
              Heard Global Corporate Headquarters:
              <br /> Charlotte, NC <br /> 624 Matthews Mint Hill Road, Suite
              150, Matthews, NC <br /> 28105 engage@heardglobal.com | Tel:
              800-884-4355
            </p>
            <p style={{ color: "#ddd", lineHeight: "1.5em" }}>
              Supporting Engagements <br /> Worldwide From:
            </p>
            <Box sx={{ display: "flex", marginLeft: "30%" }}>
              <Box sx={{ textAlign: "left" }}>
                <p style={{ color: "#ddd", lineHeight: "1.5em" }}>
                  St Louis,
                  <br /> MO Atlanta,
                  <br /> GA Austin,
                  <br /> TX SoCal,
                  <br /> CA Denver, CO
                </p>
              </Box>
              <Box sx={{ textAlign: "left", marginX: "30px" }}>
                <p style={{ color: "#ddd", lineHeight: "1.5em" }}>
                  New York,
                  <br /> NY Boston,
                  <br /> MA London,
                  <br /> UK Dubai,
                  <br /> UAE Cairo,
                  <br /> EG Saudi
                </p>
              </Box>
            </Box>
          </Grid>
          <Box sx={{}}>
            <Divider
              orientation="vertical"
              sx={{ height: "80%", backgroundColor: "#fff" }}
            />
          </Box>
          <Grid item lg={2}>
            <Box sx={{ display: "block", marginLeft: "20px" }}>
              <NavLink
                style={{
                  textDecoration: "none",
                  color: "#ddd",
                }}
                to="/"
              >
                Home
              </NavLink>
              <br />
              <NavLink
                to="/event-production"
                style={{ textDecoration: "none", color: "#ddd" }}
              >
                Capabilities
              </NavLink>
              <br />
              <NavLink
                to="/contact-us"
                style={{ textDecoration: "none", color: "#ddd" }}
              >
                Engage Us
              </NavLink>
              <br />
              <NavLink
                to="/blog"
                style={{ textDecoration: "none", color: "#ddd" }}
              >
                Blog
              </NavLink>
              <br />
              <NavLink
                to="/careers"
                style={{ textDecoration: "none", color: "#ddd" }}
              >
                Careers
              </NavLink>
            </Box>
          </Grid>

          <Grid
            item
            lg={12}
            sx={{
              textAlign: "center",
              paddingTop: "5%",
              paddingBotoom: "2%",
              marginLeft: "-10%",
            }}
          >
            <Box sx={{ marginY: "10px", color: "#fff" }}>
              ©2023 heardglobal.com
            </Box>
            <Box sx={{ color: "#fff" }}>Social App Icon's</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
