import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = (props) => {
  const { SubTitle } = props;
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: "18px", md: "18px", sm: "16px", xs: "18px" },
            lineHeight: "normal",
            textAlign: "center",
            fontFamily: "Montserrat",
            color: "#000",
            letterSpacing: "0.20em",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
          variant="h1"
        >
          {SubTitle}
        </Typography>
      </Box>
    </>
  );
};

export default Heading;
