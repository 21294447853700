import { Box } from "@mui/material";
import React from "react";

const Hero = () => {
  return (
    <>
      {" "}
      <Box sx={{ marginTop: "-10%" }}>
        <video
          role="presentation"
          crossOrigin="anonymous"
          playsInline
          preload="auto"
          muted
          loop
          autoPlay
          tabIndex={-1}
          src="https://video.wixstatic.com/video/e9f997_26ece057e2e440b2a6fa6733c49ff939/1080p/mp4/file.mp4"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
            opacity: 1,
          }}
        />
      </Box>
    </>
  );
};

export default Hero;
