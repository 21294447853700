import React from "react";
import Service from "../../components/single-service/Service";
import SEO from "../../hooks/SEO";
const Event = () => {
  return (
    <>
      <SEO
        title="Event Design & Decor | HEARD GLOBAL"
        description="Beginner friendly page for learning React Helmet."
        name="Heard Global."
        type="Event-Page"
      />
      <Service
        header="EVENT DESIGN & DECOR"
        subHeader="Captivate audiences through dramatically designed
                    engagements that enhance your story."
        media="https://video.wixstatic.com/video/e9f997_d71742702a2c485bab28b52a321e2386/720p/mp4/file.mp4"
        img="https://static.wixstatic.com/media/e9f997_6fbed1c25cf249ff9d25d45c1f0b8a90~mv2.jpg/v1/fill/w_1536,h_417,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e9f997_6fbed1c25cf249ff9d25d45c1f0b8a90~mv2.jpg"
      />
    </>
  );
};

export default Event;
