import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/Heard Brand_Centered Combo Markwhite.webp";
import { Breaker, ClientIndust } from "../sections";
const Service = (props) => {
  const { media, header, subHeader, img } = props;
  return (
    <>
      <Box
        sx={{
          marginTop: "-15%",
          marginX: "-3%",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box
            component="video"
            sx={{
              maxHeight: { lg: "100%", md: "100%", xs: "100%" },
            }}
            width="100%"
            alt="Heard-Global"
            src={media}
            role="presentation"
            crossOrigin="anonymous"
            playsInline
            preload="auto"
            muted
            loop
            autoPlay
            tabIndex={-1}
          />{" "}
          <Box
            sx={{
              position: "relative",
              marginTop: { lg: "-30%", md: "-35%", xs: "-45%" },
            }}
          >
            <Grid container lg={12}>
              <Grid item lg={12} xs={12} sx={{ textAlign: "center" }}>
                <Box
                  component="img"
                  sx={{
                    maxHeight: { lg: "100%", md: "100%", xs: "100%" },
                  }}
                  width="5%"
                  alt="Heard-Global"
                  src={logo}
                />{" "}
              </Grid>
              <Grid item lg={12} xs={12}>
                <Box sx={{ paddingBottom: "2%" }}>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "23px",
                        md: "23px",
                        sm: "16px",
                        xs: "18px",
                      },
                      lineHeight: "normal",
                      textAlign: "center",
                      fontFamily: "inherit",
                      color: "#fff",
                      fontWeight: 350,
                      letterSpacing: "0.20em",
                      marginTop: { lg: "15px", xs: "5px" },
                    }}
                    variant="h1"
                  >
                    {header}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Box
                  sx={{
                    paddingBottom: "3%",
                    marginX: { lg: "30%", md: "30%", sm: "26%", xs: "25%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "20px",
                        md: "23px",
                        sm: "16px",
                        xs: "16px",
                      },
                      lineHeight: "normal",
                      textAlign: "center",
                      fontFamily: "inherit",
                      color: "#fff",
                      fontWeight: 350,
                      letterSpacing: "0.05em",
                      marginTop: { lg: "15px", xs: "-5px" },
                    }}
                    variant="h3"
                  >
                    {subHeader}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ marginTop: "5%" }}>
          <Breaker img={img} />
        </Box>
        <Box sx={{ marginTop: "-20%" }}>
          <ClientIndust />
        </Box>
      </Box>
    </>
  );
};

export default Service;
