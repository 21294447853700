import React from "react";
import { Divider } from "@mui/material";
import BFT from "../../components/Tag-1/BFT";
import BFS from "../../components/Tag-2/BFS";

const Blog = () => {
  return (
    <>
      <BFT />
      <Divider />
      <BFS />
    </>
  );
};

export default Blog;
