import React from "react";
import { useParams } from "react-router";
import Data from "../../posts";
import { Box } from "@mui/material";

const Post = () => {
  const { postId } = useParams();
  const singlePost = Data.find((prod) => prod.id === postId);
  return (
    <Box>
      <h1>{singlePost.title}</h1>
      <p>{singlePost.price}</p>
      <p>{singlePost.description}</p>
    </Box>
  );
};

export default Post;
