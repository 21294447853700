import { Box } from "@mui/material";
import React from "react";

const Breaker = (props) => {
  const { img } = props;
  return (
    <>
      <Box
        sx={{
          marginTop: "10%",
          width: "150%",
          height: "70vh",
          objectFit: "fill",
          backgroundPosition: "center",
          backgroundRepeat: "repeat",
          backgroundImage: `url(${img})`,
          backgroundAttachment: "fixed",
          overflow: "hidden",
          display: "flex",
        }}
      />
    </>
  );
};

export default Breaker;
