import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";
import { Box } from "@mui/material";
import Footer from "./footer/Footer";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  minWidth: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "visible",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function Layout() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StyledRoot>
        <Header id="navbar" onOpenNav={() => setOpen(true)} />

        <Box
          sx={{ display: { lg: "none", md: "none", sm: "flex", xs: "flex" } }}
        >
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />
        </Box>
        <Main>
          <Outlet />
        </Main>
      </StyledRoot>
    </>
  );
}
