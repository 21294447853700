/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: "1",
    title: "Grumpy Cat Poster",
    description: "Everyone's favorite cat who loves to hate",
    price: 15,
  },
  {
    id: "2",
    title: "Stretch Armstrong",
    description:
      "He bends! He stretches! He even ties in knots, but always returns to his original shape!",
    price: 20,
  },
  {
    id: "3",
    title: "Hungry Hungry Hippos Game",
    description:
      "It's a race, it's a chase, hurry up and feed their face! Who will win? No one knows! Feed the hungry hip-ip-pos!",
    price: 30,
  },
  {
    id: "4",
    title: "Crossfire board game",
    description: "You'll get caught up in the crossfire!",
    price: 35,
  },
];
