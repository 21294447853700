import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = (props) => {
  const { title } = props;
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: "23px", md: "23px", sm: "16px", xs: "18px" },
            lineHeight: "normal",
            textAlign: "center",
            fontFamily: "inherit",
            color: "#000",
            fontWeight: 600,
            letterSpacing: "0.20em",
            marginTop: "15px",
          }}
          variant="h1"
        >
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default Heading;
