import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Blog from "./blog/blog";

const endpoint = "http://localhost:8000/posts";

const CreatePost = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [tag, setTag] = useState("");

  useEffect(() => {
    axios.get(endpoint).then((res) => {
      setData(res.data);
    });
  }, []);

  const handleTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const handleAuthor = (e) => {
    e.preventDefault();
    setAuthor(e.target.value);
  };
  const handleTag = (e) => {
    e.preventDefault();
    setTag(e.target.value);
  };

  const createPost = (e) => {
    e.preventDefault();
    axios
      .post(endpoint, {
        title: title,
        author: author,
        tags: tag,
      })
      .then((res) => {
        setData(res.data);
      });

    alert("Form Submitted ");
    window.location.reload({}, 5000);
  };
  return (
    <>
      <Box>
        <h1> Create New Post </h1>
      </Box>
      <Grid container lg={12}>
        <Grid item lg={6}>
          <form onSubmit={createPost}>
            <input
              type="text"
              value={title}
              onChange={handleTitle}
              placeholder="Type Title Here"
            />
            <input
              type="text"
              value={author}
              onChange={handleAuthor}
              placeholder="Type Author Here"
            />{" "}
            <label>
              Select A Tag:
              <select name="selectedTag" value={tag} onChange={handleTag}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </label>
          </form>

          <Box>
            <button type="submit" onClick={createPost}>
              createPost
            </button>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Blog />
        </Grid>
      </Grid>
    </>
  );
};

export default CreatePost;
