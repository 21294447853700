import React from "react";
import { Landing, Event } from "./pages";
import Layout from "./layouts/Layout";
import { Navigate, Route, Routes } from "react-router";
import Footer from "./layouts/footer/Footer";

import Post from "./pages/blog/post";
import Blog from "./pages/blog/blog";
import CreatePost from "./pages/CreatePost";

// layouts

// ----------------------------------------------------------------------

const Router = () => {
  return (
    <>
      <Routes>
        {/* Landing-Page-Route */}
        <Route element={<Layout />}>
          <Route path="/" element={<Landing />} exact />

          {/* Capabilities */}
          <Route path="/event-production" element={<Event />} />
          <Route path="/design-decor" element="designDecor" />
          <Route path="/sports-and-entertainment-venues" element="sports" />
          <Route
            path="/destination-management"
            element="destinationManagement"
          />
          <Route path="/events-experiential" element="eventsExperiential" />
          <Route path="/brand-activations-tours" element="brandTours" />
          <Route path="/contact-us" element="contact" />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/new-post" element={<CreatePost />} />

          <Route path="/post/:id" element={<Post />} />
          <Route path="/careers" element="careers" />
          <Route path="/careers/:id" element="careers" />
          <Route path="/404" element="Page404" />
          <Route path="*" element=<Navigate to="/404" replace /> />
        </Route>
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
